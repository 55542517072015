

$(document).ready(function(){


    if (!navigator.canShare) {
        $(".btn-share").hide();//cant share wont share
    }


    async function shareImage(image,title,url) {


        // console.log('shareImage',image,title,url);

        // const response = await fetch(image, { mode: 'no-cors'});

        // console.log('shareImage',response)

        // const blob = await response.blob();
        // const filesArray = [
        //   new File(
        //     [blob],
        //     'profile.png',
        //     {
        //       type: "image/png",
        //       lastModified: new Date().getTime()
        //     }
        //  )
        // ];
        const shareData = {
          //files: filesArray,
          url: url,
          title: title,
          //text: 'text'+title,
          body: 'body: '+title,
          message: 'message: '+title,
          description: 'description: '+title,
        };
        navigator.share(shareData);
    }


    $(document).on("click",".btn-share",function(e){
        e.preventDefault();
        if (navigator.canShare) {

            var $t = $(this)
            var stitle = $t.data("stitle")
            var url = $t.data("surl")
            var image = $t.data("simg")


            shareImage(image,stitle,url)
            // navigator.share(sdata).then(() => {
            //   console.log('Thanks for sharing!');
            // })
            // .catch(console.error);
        } else {
          alert("show classic share")
        }
    })

});

